import React, { useState } from 'react';
import { connect } from 'react-redux';
import { MDBBtn, MDBCol, MDBRow } from 'mdbreact';
import { getContractorTaxInfo, getContractorBankingInfo, changeDefaultDistance } from '../../../../storage/actions/profile';
import { toggleModal } from '../../../../storage/actions/modalsActions';
import InputRange from '../../../dashboard/inputRange';

const ButtonControl = ({
  profile,
  toggleModal,
  getContractorTaxInfo,
  getContractorBankingInfo,
  changeDefaultDistance
}) => {

  const [range, changeRange] = useState(profile.defaultDistance || 60)
  const handleSetDefaultRange =  () => {
     changeDefaultDistance(range)
  }
  const openTaxInformationModal = () => {
    getContractorTaxInfo()
      .then(res => {
        if (res?.data?.success) toggleModal(true, 'contractorTaxInformationModal');
      });
  };

  const openBankingInformationModal = () => {
    getContractorBankingInfo()
      .then(res => {
        if (res?.data?.success) toggleModal(true, 'addBankInfoForContractor');
      });
  };

  return (
    <>
    <MDBRow>
      <MDBCol>
        <MDBBtn
          onClick={openTaxInformationModal}
        >
          Tax Info
        </MDBBtn>
        {
          // eslint-disable-next-line no-nested-ternary
          !profile?.policingAgreements
            ? (
              <MDBBtn
                onClick={() => toggleModal(true, 'acceptAgreements')}
              >
                Policing Agreements Update Required
              </MDBBtn>
            )
            : (
              <MDBBtn
                onClick={openBankingInformationModal}
              >
                PAYMENT INFO
              </MDBBtn>
            )
        }
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol md="6">
        <InputRange defaultValue={range} handler={changeRange} />
      </MDBCol>
      <MDBCol md="6">
        <MDBBtn
          className="cwo_butt"
          style={{ height: 'calc(100% - 16px)' }}
          color="info"
          onClick={() => handleSetDefaultRange()}
        >
          Save
        </MDBBtn>
      </MDBCol>
    </MDBRow>
  </>);
};

const mapDispatchToProps = {
  toggleModal,
  getContractorTaxInfo,
  getContractorBankingInfo,
  changeDefaultDistance
};

export default connect(null, mapDispatchToProps)(ButtonControl);
